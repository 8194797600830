<template>
  <div id="app" :class="[active === 'service' && 'full-height']">
    <header class="header-wrapper">
      <div class="content-wrapper">
        <img src="~assets/images/logo.png" alt="" class="icon-logo" @click="logoButton"/>
        <ul class="nav-wrapper">
          <template v-for="menu in menuList">
            <li
              :class="['nav-item', (active === menu.menuCode || currentInChild(menu)) && 'active']"
              :key="menu.menuCode"
            >
              <span class="nav-item-title" @click="urlButton(menu.menuCode)">{{ menu.menuName }}</span>
              <template v-if="menu.child">
                <ul class="nav-item-child">
                  <template v-for="child in menu.child">
                    <li :class="{'active': active === child.menuCode }" :key="child.menuCode" @click.stop="urlButton(child.menuCode)">
                      {{ child.menuName }}
                    </li>
                  </template>
                </ul>
              </template>
            </li>
          </template>
        </ul>
        <ul class="language-wrapper">
          <template v-for="l in languageList">
            <li :key="l.key " :class="['language-item', currentLocale === l.key && 'active']" @click="languageButton(l.key)">{{l.name}}</li>
          </template>
        </ul>
      </div>
    </header>

    <router-view :class="[active !== 'service' && 'padding-top']"/>
    <my-footer v-show="active !== 'service'"></my-footer>

    <div :class="['menu-icon-wrapper', mobileMenu && 'active']" @click="mobileMenu = !mobileMenu">
      <div class="menu-icon"></div>
    </div>
    <div :class="['mobile-menu-wrapper', mobileMenu && 'active']" @click="(mobileMenu = false)">
      <ul class="mobile-menu-ul">
        <template v-for="menu in menuList">
          <li
            :class="['mobile-menu-item', (active === menu.menuCode) && 'mobile-active']"
            :key="menu.menuCode"
            @click.stop="urlButton(menu.menuCode)"
          >
            <span>{{ menu.menuName }}</span>
            <template v-if="menu.child">
                <ul>
                  <template v-for="child in menu.child">
                    <li :key="child.menuCode" :class="['mobile-sub-menu', active === child.menuCode && 'mobile-active']" @click.stop="urlButton(child.menuCode)">
                      {{ child.menuName }}
                    </li>
                  </template>
                </ul>
              </template>
          </li>
        </template>
        <li class="mobile-menu-item">
          <span>{{$t("languageTitle")}}</span>
          <ul>
            <template v-for="l in languageList">
              <li :key="l.key" :class="['mobile-sub-menu', 'language-item', currentLocale === l.key && 'mobile-active']" @click="languageButton(l.key)">{{l.name}}</li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import myFooter from "components/footer";

export default {
  components: {
    myFooter
  },
  data() {
    return {
      active: "",
      menuList: [
        {
          menuCode: "about",
          menuName: this.$t('nav.aboutus'),
        },
        {
          menuCode: "",
          menuName: this.$t('nav.service'),
          child: [
            {
              menuCode: "purchase",
              menuName: this.$t('nav.procurement'),
            },
            {
              menuCode: "bean-sprout",
              menuName: this.$t('nav.bean'),
            },
          ],
        },
        {
          menuCode: "product",
          menuName: this.$t('nav.product'),
        },
        {
          menuCode: "contact",
          menuName: this.$t('nav.contactus'),
        },
      ],
      languageList: [
        {
          key: 'zh',
          name: "中文"
        },
        {
          key: 'en',
          name: "English"
        },
      ],
      mobileMenu: false
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
  
        if (route.name === 'service') {
          document.documentElement.className = "full-height";
          document.body.className = "full-height";
        }
        else {
          document.documentElement.className = "";
          document.body.className = "";
        }

        //重置滚动高度
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    }
  },
  created() {
    //记录当前active
    this.active = this.$route.name;
  },
  mounted() {
    console.log('-----');
    console.log(this);
  },
  methods: {
    logoButton() {
      this.urlButton('service');
    },
    languageButton(key) {

      if (this.currentLocale === key) return;

      window.location.href = `${window.location.protocol}//${key === 'zh' ? 'www' : key}.xiangjiangfoods.com`;

    },
    urlButton(type) {

      if (this.active === type || !type) return;

      this.active = type;
      this.$router.push({ name: type });

      this.mobileMenu = false;
      
    },
    //判断当前是否在子项
    currentInChild(parent) {

      let flag = false;
      
      if (parent.child?.length) {
        flag = parent.child.some((c) => c.menuCode === this.active);
      }

      return flag;
    }
  },
  
};
</script>

<style lang="less" scoped>
@import "~assets/less/variable.less";

.header-wrapper {
  width: 100%;
  height: 120px;
  background: @color-white;
  position: fixed;
  z-index: 99;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

  .content-wrapper {
    display: flex;
    justify-content: space-between;

    .nav-wrapper {
      margin-top: 45px;
      .nav-item {
        display: inline-block;
        margin-left: 60px;
        font-size: 20px;
        cursor: pointer;
        position: relative;
        user-select: none;
        height: 65px;

        &-title {
          position: relative;
          display: inline-block;
          line-height: 65px;
        }

        &-child {
          position: absolute;
          z-index: 10;
          height: 0;
          top: 65px;
          left: -60px;
          width: 220px;
          text-align: center;
          background: @color-white;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          overflow: hidden;

          li {
            font-size: 18px;
            line-height: 60px;
            transition: all 0.2s ease-in-out;

            &:hover,
            &.active {
              // background: rgb(207, 122, 0 / 85%);
              background: @color-orange;
              color: @color-white;
            }
          }
        }

        &:hover {
          .nav-item-child {
            height: 120px;
            transition: all 0.2s;
          }
        }

        &:hover,
        &.active {
          .nav-item-title {
            &::after {
              display: inline-block;
              content: "";
              width: 100%;
              height: 4px;
              background: @color-orange;
              position: absolute;
              bottom: 8px;
              left: 0;
            }
          }
        }

        @media screen and (max-width: 820px) {
          margin-left: 20px;
        }
      }
    }
  }

  .icon-logo {
    width: 100px;
    height: 100px;
    margin-top: 10px;
    cursor: pointer;
  }
}

.language-wrapper {
  position: absolute;
  right: 0;
  top: 15px;

  .language-item {
    display: inline-block;
    font-size: 14px;
    user-select: none;
    cursor: pointer;

    &.active {
      color: @color-orange;

      &::before {
        content: " ";
        width: 8px;
        height: 8px;
        display: inline-block;
        background: @color-orange;
        border-radius: 50%;
        margin-right: 3px;
      }
    }

    &:hover {
      color: @color-orange;
    }

    & + .language-item {
      margin-left: 10px;
    }
  }
}

.padding-top {
  padding-top: 120px;
}

.menu-icon-wrapper,
.mobile-menu-wrapper {
  display: none;
}

@media screen and (max-width: 700px) {
  .header-wrapper {
    padding: 0 15px;
    height: 100px;
    box-sizing: border-box;

    .icon-logo {
      width: 80px;
      height: 80px;
    }
  
    .nav-wrapper {
      display: none;
    }
  }

  .padding-top {
    padding-top: 100px;
  }

  .menu-icon-wrapper {
    width: 50px;
    height: 50px;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    right: 0;
    top: 25px;
    z-index: 999;

    .menu-icon {
      width: 100%;
      height: 2px;
      background-color: @color-orange;
      position: relative;
      opacity: 1;

      &::before,
      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        background-color: @color-orange;
        position: absolute;
        left: 0;
        -webkit-transition: all .15s ease;
        transition: all .15s ease;
      }

      &::before {
        transform: translateY(-5px);
      }

      &::after {
        transform: translateY(5px);
      }
    }

    &.active {
      .menu-icon {
        background-color: transparent;
  
        &::before {
          transform: translateY(0) rotate(-45deg);
        }
  
        &::after {
          transform: translateY(0) rotate(45deg);
        }
      }
    }
  }

  .language-wrapper {
    display: none;
  }

  .mobile-menu-wrapper {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2);
    padding-top: 100px;
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 90;
    transition: all .3s ease-in-out;
    display: block;

    .mobile-menu-ul {
      background: @color-white;
      width: 100%;
      height: auto;

      .mobile-menu-item {
        font-size: 0px;
        padding: 0 15px;
        border-bottom: 1px solid #E0E0E0;

        span {
          font-size: 16px;
          line-height: 50px;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .mobile-sub-menu {
        font-size: 14px;
        color: @font-gray;
        line-height: 40px;
        padding: 0 15px;

        &.language-item {
          display: inline-block;
        }
      }

      .mobile-active {
        color: @color-orange;

        &::after {
          content: " ";
          display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: @color-orange;
          margin-left: 10px;
        }
      }
    }

    &.active {
      top: 0;
    }
  }
}
</style>
